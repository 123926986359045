<template>
    <div id="courier">
        <router-view></router-view>
    </div>
</template>
<script>
  import { inobounce } from 'inobounce'

  export default {
    name: 'couponCard',
    data () {
      return {}
    },
    created () {
      document.title = '礼品卡'
      let path = this.$route.query.path
      let query = ''
      if (this.$route.query.query != 'undefined') {
        query = this.$route.query.query
      }
      if (!this.$utils.rule.empty(query)) {
        query = JSON.parse(decodeURIComponent(query))
      }
      if (path) {
        this.$router.replace({path: 'coupon-card/' + path, query: query})
      }
    },
  }
</script>
<style scoped>
    #courier {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100vh;
        font-family: PingFangSC-Regular, PingFang SC;

    }
</style>
